<template>
  <div>
    <b-img
      :src="iconImage"
      fluid
      alt="Orangie and Me"
      class="icon-image"
    ></b-img>
    <div class="mx-5 text-msg">Hello You!</div>
  </div>
</template>

<script>
import iconImage from "../assets/icon.webp";

export default {
  name: "PublicHome",
  data() {
    return {
      iconImage,
    };
  },
  props: {},
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Use full viewport height */
}

.icon-image {
  width: 20pc;
  height: 20pc;
  object-fit: contain; /* This ensures the image keeps its aspect ratio */
}

.text-msg {
  font-size: 5pc; /* Adjust the font size as needed */
  margin-top: 20px; /* Adds some space between the image and the message */
}
</style>
