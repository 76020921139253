<template>
  <div class="login-container mt-5">
    <h1 class="login-title">Login</h1>
    <b-form-input
      v-model="email"
      type="email"
      class="login-input"
      placeholder="Your Email"
    />
    <b-form-input
      v-model="password"
      type="password"
      class="login-input"
      placeholder="Password"
    />
    <b-button @click="performLogin" class="login-button custom-button mt-4">
      Login
    </b-button>
    <p v-if="errorMessage" class="error-message mt-3">{{ errorMessage }}</p>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
    };
  },
  methods: {
    ...mapActions(["login"]),
    async performLogin() {
      if (!this.email || !this.password) {
        this.errorMessage = "Please enter both email and password";
        return;
      }
      const success = await this.login({
        email: this.email,
        password: this.password,
      });
      if (success) {
        this.$router.push("/home");
      } else {
        this.errorMessage =
          "Login failed. Please check your email and password";
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.login-title {
  font-family: "Nunito", sans-serif;
  color: #4a4e69;
  margin-bottom: 20px;
}

.login-input {
  margin-bottom: 15px;
}

.custom-button {
  background-color: #6b8baf; /* Custom color */
  color: #fff; /* Text color */
  border-color: #6b8baf; /* Border color */
}

.error-message {
  color: red;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}
</style>
