const timelineEvents = [
  {
    type: "image",
    date: "April 11",
    description:
      "The day we met... And it all started with the really awkward message.",
    image:
      "https://orangie-and-me-website.s3.ca-central-1.amazonaws.com/First+Message.png",
    orientation: "landscape",
  },
  {
    type: "image",
    date: "April 12",
    description: "The next day",
    image:
      "https://orangie-and-me-website.s3.ca-central-1.amazonaws.com/First+Message.png",
    orientation: "landscape",
  },
  {
    type: "image",
    date: "April 13",
    description: "The next next day",
    image:
      "https://orangie-and-me-website.s3.ca-central-1.amazonaws.com/First+Message.png",
    orientation: "landscape",
  },
  {
    type: "quote",
    date: "February 14",
    description:
      "“Love is not about how many days, months, or years you have been together. Love is about how much you love each other every single day.”",
  },
  {
    type: "quote",
    date: "February 14",
    description:
      "“Love is not about how many days, months, or years you have been together. Love is about how much you love each other every single day.”",
  },
  {
    type: "quote",
    date: "February 14",
    description:
      "“Love is not about how many days, months, or years you have been together. Love is about how much you love each other every single day.”",
  },
  {
    type: "image",
    date: "April 10",
    description: "The day we adopted our pet. A new member of our family.",
    image: "https://via.placeholder.com/300x450",
    orientation: "portrait",
  },
  {
    type: "quote",
    date: "July 20",
    description:
      "“The best thing to hold onto in life is each other.” – Audrey Hepburn",
  },
  {
    type: "image",
    date: "October 31",
    description: "Celebrating Halloween together for the first time.",
    image: "https://via.placeholder.com/450x300",
    orientation: "landscape",
  },
  {
    type: "quote",
    date: "December 25",
    description:
      "“Christmas is not as much about opening our presents as opening our hearts.”",
  },
];

export default timelineEvents;
