<template>
  <div id="app">
    <nav>
      <div class="container">
        <div class="left-section">
          <router-link v-if="isAuthenticated" to="/home" class="logo">
            <h1 class="site-name">
              <span style="color: #ff9f00">Orangie</span>
              & Me
            </h1>
          </router-link>
          <router-link v-else to="/" class="logo">
            <h1 class="site-name">
              <span style="color: #ff9f00">Orangie</span>
              & Me
            </h1>
          </router-link>
        </div>
        <div class="right-section">
          <router-link
            v-if="isAuthenticated"
            to="/home"
            class="nav-link"
            :style="{ color: isCurrentRoute('/home') ? '#ff9f00' : '' }"
          >
            <b-icon v-if="isMobile" icon="house-door-fill"></b-icon>
            <span v-else>Home</span>
          </router-link>
          <router-link
            v-if="isAuthenticated"
            to="/timeline"
            class="nav-link"
            :style="{ color: isCurrentRoute('/timeline') ? '#ff9f00' : '' }"
          >
            <b-icon v-if="isMobile" icon="calendar-check-fill"></b-icon>
            <span v-else>Our Timeline</span>
          </router-link>
          <router-link
            v-if="isAuthenticated"
            to="/terms-of-endearment"
            class="nav-link"
            :style="{
              color: isCurrentRoute('/terms-of-endearment') ? '#ff9f00' : '',
            }"
          >
            <b-icon v-if="isMobile" icon="heart-fill"></b-icon>
            <span v-else>Terms of Endearment</span>
          </router-link>
          <router-link
            v-if="isAuthenticated && isAdmin"
            to="/admin"
            class="nav-link"
            :style="{ color: isCurrentRoute('/admin') ? '#ff9f00' : '' }"
          >
            <b-icon v-if="isMobile" icon="gear-fill"></b-icon>
            <span v-else>Admin</span>
          </router-link>
          <router-link
            v-if="!isAuthenticated"
            to="/login"
            class="nav-link"
            :style="{ color: isCurrentRoute('/login') ? '#ff9f00' : '' }"
          >
            <b-icon v-if="isMobile" icon="person-fill"></b-icon>
            <span v-else>Login</span>
          </router-link>
          <router-link
            v-if="isAuthenticated"
            to="/logout"
            class="nav-link"
            :style="{ color: isCurrentRoute('/logout') ? '#ff9f00' : '' }"
          >
            <b-icon v-if="isMobile" icon="box-arrow-right"></b-icon>
            <span v-else>Logout</span>
          </router-link>
        </div>
      </div>
    </nav>
    <router-view></router-view>
    <!-- Floating button for scroll to top -->
    <b-button
      v-show="shouldShowScrollToTopButton"
      @click="scrollToTop"
      class="floating-button"
    >
      <b-icon icon="arrow-up" font-scale="1"></b-icon>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      shouldShowScrollToTopButton: false,
      isMobile: false, // Flag to determine if the device is mobile
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    isCurrentRoute(route) {
      return this.$route.path === route;
    },
    checkMobile() {
      // Check if the device is mobile
      this.isMobile = window.innerWidth <= 768; // Assuming mobile devices have width <= 768px
    },
  },
  created() {
    // Check for scroll position to toggle visibility of scroll to top button
    window.addEventListener("scroll", () => {
      this.shouldShowScrollToTopButton = window.scrollY > 100;
    });
    this.checkMobile(); // Check for mobile device on component creation
    window.addEventListener("resize", this.checkMobile); // Listen for resize events
  },
  destroyed() {
    // Remove scroll and resize event listeners on component destruction
    window.removeEventListener("scroll", () => {});
    window.removeEventListener("resize", this.checkMobile);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=Roboto:wght@400;500&display=swap");

#app {
  font-family: "Nunito", sans-serif;
  text-align: center;
}

body {
  background-color: #fff0f5;
  font-family: "Roboto", sans-serif;
}

h1 {
  font-family: "Nunito", sans-serif;
  color: #4a4e69;
  font-size: 2.5rem;
}

h2 {
  font-family: "Nunito", sans-serif;
  color: #22223b;
  margin-bottom: 1rem;
}

p {
  font-size: 1rem;
  line-height: 1.7;
  color: #4a4e69;
}

nav {
  background-color: #f0f3f8; /* Soft blue-gray background */
  color: #485369; /* Subdued text color */
  padding: 15px 0;
  margin-bottom: 2pc;
  border-bottom: 2px solid #d3dce6; /* Subtle border */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
}

.left-section,
.right-section {
  display: flex;
  align-items: center;
}

.right-section {
  justify-content: flex-end;
}

.center-section {
  flex-grow: 1; /* Allow the center section to grow and take available space */
}

.site-name {
  margin: 0;
  font-size: 1.8rem; /* Larger font size for site name */
  color: #6b8baf; /* Light blue color */
}

.logo {
  text-decoration: none;
}

.logo:hover {
  text-decoration: none;
}

.nav-link {
  color: #485369; /* Text color */
  text-decoration: none;
  font-size: 1.2rem;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #6b8baf; /* Light blue on hover */
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ff9f00; /* Light orange background */
  border: 2px solid #ff9f00; /* Slightly darker orange border */
  color: #fff; /* Dark gray text color for contrast */
  font-weight: bolder; /* Makes the button text stand out */
  border-radius: 50%; /* Rounded shape */
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effects */
}

.floating-button:hover {
  background-color: #fca031; /* Lighter orange on hover for interactive feel */
  transform: translateY(-2px); /* Slight lift effect on hover */
}

@media (max-width: 768px) {
  .container {
    padding: 0 15px;
  }

  .nav-link {
    font-size: 1rem;
    margin: 0 5px;
  }
}
</style>
