// src/firebaseConfig.js
// Updated imports for Firebase v9 and later
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD7d-a-0YCLnBfTTxc9S4pWjhFsry6Ok1k",
  authDomain: "orangie-and-me-website.firebaseapp.com",
  projectId: "orangie-and-me-website",
  storageBucket: "orangie-and-me-website.appspot.com",
  messagingSenderId: "856761547322",
  appId: "1:856761547322:web:761133fbc33ff06097d303",
  measurementId: "G-E67YD1PG7Z",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const storage = getStorage(app); // Initialize Firebase Storage

// Export the Firebase services
export { db, auth, analytics, storage }; // Don't forget to export storage
