import Vue from "vue";
import Vuex from "vuex";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import events from "./events";

Vue.use(Vuex);

const auth = getAuth();

export default new Vuex.Store({
  modules: {
    events,
  },
  state: {
    isAuthenticated: false,
    isAdmin: false,
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.isAuthenticated = value;
    },
    SET_ADMIN(state, value) {
      state.isAdmin = value;
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        if (
          (email && email === "sandu.rajapakse@gmail.com") ||
          (email && email === "chathumi.333@gmail.com")
        ) {
          commit("SET_ADMIN", true);
        }
        commit("SET_AUTHENTICATED", true);
        return true;
      } catch (error) {
        console.error("Login error:", error);
        commit("SET_AUTHENTICATED", false);
        return false;
      }
    },
    async logout({ commit }) {
      try {
        await signOut(auth);
        commit("SET_AUTHENTICATED", false);
        commit("SET_ADMIN", false);
      } catch (error) {
        console.error("Logout error:", error);
      }
    },
    // Define the handleAuthStateChanged action
    handleAuthStateChanged({ commit }) {
      return new Promise((resolve) => {
        auth.onAuthStateChanged((user) => {
          commit("SET_AUTHENTICATED", !!user);

          if (
            (user && user.email === "sandu.rajapakse@gmail.com") ||
            (user && user.email === "chathumi.333@gmail.com")
          ) {
            commit("SET_ADMIN", true);
          }
          resolve();
        });
      });
    },
  },
});
