<!-- <template>
  <div class="admin-page container-fluid my-5">
    <b-card>
      <h1 class="text-center mb-4">
        {{ editMode ? "Edit Event" : "Add New Event" }}
      </h1>
      <b-form @submit.prevent="submitEvent">
        <b-row>
          <b-col md="3">
            <b-form-group label="Type" label-for="type">
              <b-form-select
                id="type"
                v-model="newEvent.type"
                class="p-2 my-3"
                required
                :options="typeOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Date" label-for="date">
              <b-form-input
                type="date"
                id="date"
                v-model="newEvent.date"
                class="p-2 my-3"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group label="Show Date" label-for="showDate">
              <b-form-checkbox
                id="showDate"
                v-model="newEvent.showDate"
                class="my-3"
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Description" label-for="description">
          <b-form-textarea
            id="description"
            v-model="newEvent.description"
            required
            placeholder="Enter event description"
            rows="3"
            class="p-2 my-3"
          ></b-form-textarea>
        </b-form-group>
        <b-row>
          <b-col md="6" v-if="newEvent.type === 'image'">
            <b-form-group label="Orientation" label-for="orientation">
              <b-form-select
                id="orientation"
                v-model="newEvent.orientation"
                class="p-2 my-3"
                required
                :options="orientationOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="newEvent.type === 'image'">
            <b-form-group label="Image" label-for="image" class="p-2 my-3">
              <input type="file" id="image" @change="handleImageChange" />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="newEvent.type === 'quote'">
            <b-form-group
              label="Quote Text"
              label-for="quoteText"
              class="p-2 my-3"
            >
              <b-form-textarea
                id="quoteText"
                v-model="newEvent.quote"
                placeholder="Enter quote description"
                rows="3"
                class="p-2 my-3"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="text-center">
          <b-button type="submit" :variant="editMode ? 'warning' : 'primary'">{{
            editMode ? "Save Changes" : "Add Event"
          }}</b-button>
        </div>
        {{ newEvent }}
      </b-form>
    </b-card>

    <b-toast
      v-model="showSuccessToast"
      title="Success"
      variant="success"
      no-auto-hide
    >
      {{
        editMode ? "Event edited successfully!" : "Event added successfully!"
      }}
    </b-toast>
  </div>
</template> -->

<template>
  <div class="admin-page container-fluid my-5">
    <b-card>
      <h1 class="text-center mb-4">
        {{ editMode ? "Edit Event" : "Add New Event" }}
      </h1>
      <b-form @submit.prevent="submitEvent">
        <b-row>
          <b-col md="3">
            <b-form-group label="Type" label-for="type">
              <b-form-select
                id="type"
                v-model="newEvent.type"
                class="p-2 my-3"
                required
                :options="typeOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Date" label-for="date">
              <b-form-input
                type="date"
                id="date"
                v-model="newEvent.date"
                class="p-2 my-3"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group label="Show Date" label-for="showDate">
              <b-form-checkbox
                id="showDate"
                v-model="newEvent.showDate"
                class="my-3"
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Description" label-for="description">
          <b-form-textarea
            id="description"
            v-model="newEvent.description"
            required
            placeholder="Enter event description"
            rows="3"
            class="p-2 my-3"
          ></b-form-textarea>
        </b-form-group>
        <b-row>
          <b-col md="3" v-if="newEvent.type === 'image'">
            <b-form-group label="Orientation" label-for="orientation">
              <b-form-select
                id="orientation"
                v-model="newEvent.orientation"
                class="p-2 my-3"
                required
                :options="orientationOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="newEvent.type === 'image'">
            <b-form-group label="Image" label-for="image">
              <input
                type="file"
                id="image"
                @change="handleImageChange"
                class="p-2 my-3"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="newEvent.type === 'image' && newEvent.imageUrl">
            <b-img
              :src="newEvent.imageUrl"
              :alt="'Image for ' + newEvent.date"
              fluid
              class="timeline-image"
              :class="{
                portrait: newEvent.orientation === 'portrait',
                landscape: newEvent.orientation === 'landscape',
              }"
            ></b-img>
          </b-col>
          <b-col
            v-if="newEvent.type === 'image' && !newEvent.imageUrl"
            class="pt-5"
          >
            No image uploaded yet...
          </b-col>
          <b-col md="12" v-if="newEvent.type === 'quote'">
            <b-form-group
              label="Quote Text"
              label-for="quoteText"
              class="p-2 my-3"
            >
              <b-form-textarea
                id="quoteText"
                v-model="newEvent.quote"
                placeholder="Enter quote description"
                rows="3"
                class="p-2 my-3"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="text-center my-5">
          <b-button type="submit" :variant="editMode ? 'warning' : 'primary'">{{
            editMode ? "Save Changes" : "Add Event"
          }}</b-button>
        </div>
        Raw data:
        <pre>{{ newEvent }}</pre>
      </b-form>
    </b-card>

    <b-toast v-model="showSuccessToast" title="Success" variant="success">
      {{
        editMode ? "Event edited successfully!" : "Event added successfully!"
      }}
    </b-toast>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AdminPage",
  props: {
    eventDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      editMode: false, // Initially set edit mode to false
      eventId: null, // Initialize eventId to null
      newEvent: {
        date: "",
        description: "",
        type: "image",
        imageFile: null,
        quote: "",
        orientation: "landscape",
        showDate: true,
      },
      typeOptions: ["image", "quote"],
      orientationOptions: ["landscape", "portrait"],
      showSuccessToast: false,
    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const eventId = urlParams.get("id");
    if (eventId) {
      this.editMode = true;
      this.eventId = eventId;
      this.$store
        .dispatch("fetchEventById", eventId) // Dispatch fetchEventById action to retrieve event details
        .then((event) => {
          if (event) {
            this.newEvent = { ...event }; // Populate form fields with the fetched event details
          } else {
            console.error("Event not found");
          }
        })
        .catch((error) => {
          console.error("Error fetching event details:", error);
        });
    }
  },
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("success") && urlParams.get("success") === "true") {
      // Assuming there are asynchronous tasks here (e.g., fetching data)
      // Simulate an asynchronous task with setTimeout
      await new Promise((resolve) => {
        setTimeout(resolve, 1000); // Adjust the delay time as needed
      });

      // After the asynchronous tasks are completed, set showSuccessToast to true
      this.showSuccessToast = true;
    }
  },

  methods: {
    ...mapActions(["addEventWithImage", "editEventWithImage"]),
    async submitEvent() {
      if (
        this.newEvent.type === "image" &&
        !this.newEvent.imageFile &&
        !this.editMode
      ) {
        alert("Please select an image file.");
        return;
      }

      if (this.editMode) {
        // Handle editing mode
        if (await this.editEventWithImage(this.newEvent)) {
          window.location.href = "/admin?success=true";
        } else {
          alert("Failed to edit event.");
        }
      } else {
        // Handle adding mode
        if (await this.addEventWithImage(this.newEvent)) {
          window.location.href = "/admin?success=true";
        } else {
          alert("Failed to add event.");
        }
      }
    },
    handleImageChange(event) {
      this.newEvent.imageFile = event.target.files[0];
    },
  },
};
</script>

<style scoped>
.admin-page {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.b-form-input,
.b-form-textarea,
.b-form-select {
  margin-bottom: 15px;
  padding: 10px;
}

.b-form-select {
  border-radius: 5px;
  border: 1px solid #ced4da;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  background-color: #fff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.b-form-select:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.b-form-select-option {
  background-color: #fff;
}

@media (min-width: 768px) {
  .admin-page {
    width: 95%;
  }
}

h1 {
  margin-bottom: 2rem;
}
</style>

<style scoped>
.admin-page {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.b-form-input,
.b-form-textarea,
.b-form-select {
  margin-bottom: 15px;
  padding: 10px;
}

.b-form-select {
  border-radius: 5px;
  border: 1px solid #ced4da;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  background-color: #fff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.b-form-select:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.b-form-select-option {
  background-color: #fff;
}

.image-container {
  padding: 1rem;
}

.timeline-image {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transition: transform 0.3s ease;
  max-width: 75%; /* Ensure the image doesn't exceed its container width */
  height: auto; /* Maintain aspect ratio */
}

.timeline-image:hover {
  transform: scale(1.03);
}

@media (min-width: 768px) {
  .admin-page {
    width: 95%;
  }
}

h1 {
  margin-bottom: 2rem;
}
</style>
