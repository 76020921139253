<template>
  <div id="app" class="container">
    <div>
      <b-row>
        <b-col cols="12" class="pb-5 mb-5">
          <!-- Place the h2 elements outside of the loop -->
          <h1 class="text-center" data-aos="fade-down">Terms of Endearment</h1>
          <p class="mb-3" data-aos="fade-down">
            These are all words that I have used as terms of endearment for
            you...
          </p>
          <b-button
            @click="toggleEmojis"
            class="custom-button"
            data-aos="fade-down"
          >
            {{ showEmojis ? "Hide Emojis" : "Enhance with Emojis" }}
          </b-button>
          <b-row class="pb-5 my-5">
            <b-col
              cols="3"
              v-for="(group, letter) in categorizedWords"
              :key="letter"
              data-aos="fade-up"
            >
              <h2>{{ letter }}</h2>
              <ul>
                <li
                  v-for="(wordObj, index) in group"
                  :key="index"
                  :class="{ primary: index === 0 }"
                  data-aos="fade-up"
                  :data-aos-delay="index * 100"
                >
                  {{
                    showEmojis
                      ? `${wordObj.words} ${wordObj.emojis}`
                      : removeEmojis(wordObj.words)
                  }}
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AOS from "aos";

export default {
  data() {
    return {
      showEmojis: false, // Change the initial value to false to hide emojis by default
      words: [
        { words: "Angel", emojis: "👼", default: false },
        { words: "Babe", emojis: "👶", default: false },
        { words: "Baby", emojis: "👶", default: false },
        { words: "Babygirl", emojis: "👶👧", default: true },
        { words: "Baby blue", emojis: "👶💙", default: false },
        { words: "Baby boo", emojis: "👶👻", default: false },
        { words: "Beautiful", emojis: "🌸", default: false },
        { words: "Blueberry muffin", emojis: "🫐🧁", default: false },
        { words: "Bubsy wubbzy", emojis: "🐻‍❄️", default: false },
        { words: "Butterfly", emojis: "🦋", default: false },
        { words: "Bunny", emojis: "🐰", default: false },
        { words: "Bubble gum", emojis: "💭🍬", default: false },
        { words: "Cuddle bunny", emojis: "🐰🤗", default: false },
        { words: "Chocolate chip", emojis: "🍫🍪", default: false },
        { words: "Cutie", emojis: "🥰", default: false },
        { words: "Cutie Pie", emojis: "🥧🥰", default: true },
        { words: "Cupcake", emojis: "🧁", default: false },
        { words: "Darling", emojis: "💖", default: true },
        { words: "Duckling", emojis: "🐥", default: false },
        { words: "Dumpling", emojis: "🥟", default: false },
        { words: "Eternal Love", emojis: "💞", default: false },
        { words: "Fiancé", emojis: "👩‍❤️‍👨", default: true },
        { words: "Firefly", emojis: "🔥🦋", default: false },
        { words: "Gumdrop", emojis: "🍬", default: false },
        { words: "Honey", emojis: "🍯", default: true },
        { words: "Honey Bee", emojis: "🍯🐝", default: false },
        { words: "Honey Bunch", emojis: "🍯🌸", default: true },
        { words: "Iconic girl", emojis: "👩‍🎤", default: false },
        { words: "Jelly Bean", emojis: "🍬", default: false },
        { words: "Koala Bear", emojis: "🐨", default: false },
        { words: "Love", emojis: "❤️", default: true },
        { words: "Lovebug", emojis: "🐞💖", default: false },
        { words: "Love bundle", emojis: "🎀💖", default: false },
        { words: "Love bunny", emojis: "🐰💖", default: false },
        { words: "Love bird", emojis: "🕊️💖", default: false },
        { words: "Little Miss", emojis: "👧", default: false },
        { words: "Muffin", emojis: "🧁", default: false },
        { words: "Munchkin", emojis: "👶", default: true },
        { words: "Nugget", emojis: "🐥", default: false },
        { words: "Opalescent star", emojis: "🌟", default: false },
        { words: "Only love", emojis: "❤️", default: false },
        { words: "Princess", emojis: "👸", default: true },
        { words: "Precious", emojis: "💎", default: false },
        { words: "Pumpkin", emojis: "🎃", default: false },
        { words: "Queen Bee", emojis: "👑🐝", default: false },
        { words: "Raspberry", emojis: "🍇", default: false },
        { words: "Snookums", emojis: "🥰", default: false },
        { words: "Sneaky pie", emojis: "🥧🦝", default: false },
        { words: "Sweetheart", emojis: "💖", default: true },
        { words: "Sweet Pea", emojis: "🌸", default: false },
        { words: "Sweetie", emojis: "🍬", default: false },
        { words: "Sugar Crisp", emojis: "🍬🥣", default: false },
        { words: "Sweetie Manike", emojis: "🍬🎀", default: false },
        { words: "Tweetie Bird", emojis: "🐦", default: false },
        { words: "Universe", emojis: "🌌", default: false },
        { words: "Valentine", emojis: "💘", default: false },
        { words: "Waffle Cone", emojis: "🧇🍦", default: false },
        { words: "Xoxo", emojis: "💋💋", default: false },
        { words: "Xanthic princess", emojis: "👑💛", default: false },
        { words: "Yellow Bird", emojis: "🐤💛", default: false },
        { words: "Zesty Zebra", emojis: "🦓🌶️", default: false },
      ],
    };
  },
  computed: {
    categorizedWords() {
      const categorized = {};
      this.words.forEach((wordObj) => {
        const firstLetter = wordObj.words.charAt(0).toUpperCase();
        if (!categorized[firstLetter]) {
          categorized[firstLetter] = [];
        }
        categorized[firstLetter].push(wordObj);
      });

      // Sort each group of words alphabetically, with default word first if present
      for (const key in categorized) {
        categorized[key].sort((a, b) => {
          if (a.default && !b.default) return -1;
          if (!a.default && b.default) return 1;
          return a.words.localeCompare(b.words);
        });
      }

      return categorized;
    },
  },
  methods: {
    toggleEmojis() {
      this.showEmojis = !this.showEmojis;
    },
    removeEmojis(word) {
      // Removes emojis from a word
      return word.replace(/\p{Emoji}/gu, "");
    },
  },
  mounted() {
    AOS.init(); // Initialize AOS when the component is mounted
  },
};
</script>

<style scoped>
body {
  background-color: #fff0f5;
  font-family: "Roboto", sans-serif;
}

h1 {
  font-family: "Nunito", sans-serif;
  color: #4a4e69;
  font-size: 2.5rem;
}

h2 {
  font-family: "Nunito", sans-serif;
  color: #22223b;
  margin-bottom: 1rem;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 0.5rem;
  font-size: 1.3pc;
}

p {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  text-align: center; /* Centers the message text for better readability */
  max-width: 60%; /* Limits the width of text for optimal reading experience */
  margin: auto; /* Auto margin for horizontal centering */
  font-size: 1.2pc; /* Adjust based on preference */
  line-height: 1.4; /* Improves readability */
  justify-content: flex-end;
}

.primary {
  font-weight: bold;
}

.emojiz-button {
  background-color: #ff9f00;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.emojiz-button:hover {
  background-color: #ff7100;
}

.custom-button {
  background-color: #ffffff; /* Light orange background */
  border: 2px solid #ff9f00; /* Slightly darker orange border */
  color: #242424; /* Dark gray text color for contrast */
  font-weight: bold; /* Makes the button text stand out */
  border-radius: 5px; /* Rounded corners for a softer look */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effects */
}

.custom-button:hover {
  background-color: #ff9f00; /* Lighter orange on hover for interactive feel */
  transform: translateY(-2px); /* Slight lift effect on hover */
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover for depth */
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }
}
</style>
