<template>
  <div id="app" class="container">
    <b-row>
      <b-col cols="12">
        <h1 class="text-center" data-aos="fade-down">
          Our Relationship Timeline
        </h1>
        <p class="subtitle-text" data-aos="fade-down">
          Here are some interesting things that happened in the last year...
        </p>

        <div
          v-for="(event, index) in sortedEvents"
          :key="index"
          class="timeline-item"
        >
          <b-row class="align-items-center my-4 py-4">
            <b-col
              cols="12"
              md="6"
              :class="index % 2 === 0 ? 'order-md-2' : ''"
              class="text-container"
              data-aos="fade-right"
              v-if="event.type === 'quote'"
            >
              <div>
                <blockquote class="custom-blockquote">
                  <p>{{ event.quote }}</p>
                  <footer>{{ event.author }}</footer>
                </blockquote>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
              :class="index % 2 === 0 ? 'order-md-2' : ''"
              class="image-container"
              v-if="event.type === 'image'"
              data-aos="fade-up"
            >
              <b-img
                :src="event.imageUrl"
                :alt="'Image for ' + event.date"
                fluid
                class="timeline-image"
                :class="{
                  portrait: event.orientation === 'portrait',
                  landscape: event.orientation === 'landscape',
                }"
                @click="openImageModal(event.imageUrl)"
              ></b-img>
            </b-col>

            <b-col
              cols="12"
              md="6"
              :class="index % 2 === 0 ? 'order-md-1' : ''"
              class="date-container"
              data-aos="fade-up"
            >
              <h2 class="text-right">
                {{ event.formattedDate }}
                <a
                  v-if="isAdmin"
                  href="#"
                  @click.prevent="openAdminTab(event.id)"
                  class="edit-icon"
                >
                  <b-icon icon="pencil" font-scale="0.5"></b-icon>
                </a>
              </h2>
              <p v-if="event.type !== 'quote'">{{ event.description }}</p>
            </b-col>
          </b-row>
          <div class="divider"></div>
        </div>
      </b-col>
    </b-row>

    <!-- Modal for displaying larger image -->
    <b-modal v-model="showModal" size="lg" hide-header>
      <div class="d-flex justify-content-center">
        <b-img :src="modalImageUrl" fluid></b-img>
      </div>
      <template #modal-footer>
        <b-button class="custom-button" variant="primary" @click="closeModal"
          >Close</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import timelineEvents from "@/assets/events.js";
import { debounce } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "RelationshipTimeline",
  data() {
    return {
      timelineEvents,
      showModal: false,
      modalImageUrl: "",
    };
  },
  computed: {
    ...mapGetters(["events"]),
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    sortedEvents() {
      // Convert dates to UTC time
      const eventsUTC = this.events.map((event) => {
        const utcDate = new Date(event.date + "T12:00:00Z");
        return { ...event, utcDate };
      });

      // Sort events by UTC date in ascending order (older ones first)
      const sortedEvents = eventsUTC.sort((a, b) => a.utcDate - b.utcDate);

      // Extract sorted events without utcDate property
      return sortedEvents.map((event) => {
        const { ...rest } = event;
        const formattedDate = this.formatDate(event.utcDate);
        return { ...rest, formattedDate };
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init({
        once: false,
        mirror: true,
      });
      window.addEventListener(
        "scroll",
        debounce(() => {
          AOS.refresh();
        }, 100),
        { passive: true }
      );
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.refreshAOS);
  },
  methods: {
    ...mapActions(["fetchEvents"]),
    refreshAOS: debounce(function () {
      AOS.refresh();
    }, 100),
    formatDate(date) {
      const options = { month: "long", day: "numeric", year: "numeric" };
      const formattedDate = date.toLocaleDateString("en-US", options);
      const day = date.getDate();
      let suffix;
      if (day === 1 || day === 21 || day === 31) {
        suffix = "st";
      } else if (day === 2 || day === 22) {
        suffix = "nd";
      } else if (day === 3 || day === 23) {
        suffix = "rd";
      } else {
        suffix = "th";
      }
      return formattedDate.replace(/\d+/, day + suffix);
    },
    openAdminTab(eventId) {
      // Open new tab with /admin?id={event.id}
      window.open(`/admin?id=${eventId}`, "_blank");
    },
    openImageModal(imageUrl) {
      this.modalImageUrl = imageUrl;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.modalImageUrl = "";
    },
  },
  created() {
    this.fetchEvents();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=Roboto:wght@400;500&display=swap");

body {
  background-color: #fff0f5;
  font-family: "Roboto", sans-serif;
}

h1 {
  font-family: "Nunito", sans-serif;
  color: #4a4e69;
  font-size: 2.5rem;
}

h2 {
  font-family: "Nunito", sans-serif;
  color: #22223b;
  margin-bottom: 1rem;
}

p {
  font-size: 1rem;
  line-height: 1.7;
  color: #4a4e69;
}

.subtitle-text {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  text-align: center; /* Centers the message text for better readability */
  max-width: 60%; /* Limits the width of text for optimal reading experience */
  margin: auto; /* Auto margin for horizontal centering */
  font-size: 1.2pc; /* Adjust based on preference */
  line-height: 1.4; /* Improves readability */
  justify-content: flex-end;
}

.image-container {
  padding: 1rem;
}

.timeline-image {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transition: transform 0.3s ease;
  max-width: 75%; /* Ensure the image doesn't exceed its container width */
  height: auto; /* Maintain aspect ratio */
}

.timeline-image:hover {
  transform: scale(1.03);
}

.custom-blockquote {
  background-color: #d5e5ff;
  border-right: 5px solid #93a5b9;
  padding: 1rem 2rem;
  border-radius: 5px;
  font-style: normal;
  color: #22223b;
}

.custom-blockquote footer {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #6c757d;
}

.b-row:not(:last-child) {
  margin-bottom: 4rem;
}

.divider {
  border-top: 1px solid rgba(0, 0, 0, 0.1); /* Faint gray color with lower opacity */
  margin: 20px auto; /* Adjust margin as needed */
  width: 30pc;
}

.edit-icon {
  color: #666; /* Set your desired color for the edit icon */
  cursor: pointer;
  position: relative;
  top: -20px;
}

.modal-image {
  max-width: 90vw; /* Adjust the maximum width of the image in the modal */
  max-height: 90vh; /* Adjust the maximum height of the image in the modal */
}

.custom-button {
  background-color: #ff9f00; /* Orange background */
  border: none; /* Remove border */
  color: #ffffff; /* White text color for contrast */
  border-radius: 5px; /* Rounded corners for a softer look */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effects */
}

.custom-button:hover {
  background-color: #ffbd59; /* Lighter orange on hover for interactive feel */
  transform: translateY(-2px); /* Slight lift effect on hover */
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover for depth */
}

@media (max-width: 768px) {
  .image-container,
  .text-container {
    padding: 0.5rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .image-container,
  .text-container {
    order: 2;
  }

  .timeline-item {
    margin-bottom: 2rem;
  }

  h1 {
    margin-bottom: 2rem;
  }
}
</style>
