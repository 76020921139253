import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";
import PublicHome from "./components/PublicHome.vue";
import PrivateHome from "./components/PrivateHome.vue";
import LoginPage from "./components/LoginPage.vue";
import RelationshipTimeline from "./components/RelationshipTimeline.vue";
import TermsOfEndearment from "./components/TermsOfEndearment.vue";
import Admin from "./components/AdminPage.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", component: PublicHome },
  { path: "/home", component: PrivateHome, meta: { requiresAuth: true } },
  { path: "/login", component: LoginPage, meta: { requiresAuth: false } },
  {
    path: "/timeline",
    component: RelationshipTimeline,
    meta: { requiresAuth: true },
  },
  {
    path: "/terms-of-endearment",
    component: TermsOfEndearment,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    component: Admin,
    meta: { requiresAuth: true },
  },
  {
    path: "/logout",
    beforeEnter: (to, from, next) => {
      store.dispatch("logout").then(() => {
        next("/");
      });
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// Global navigation guard to check for authentication
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = store.getters.isAuthenticated;

  if (requiresAuth && !isAuthenticated) {
    // If the route requires authentication and user is not authenticated,
    // redirect to the login page.
    next("/login");
  } else if (!requiresAuth && isAuthenticated && to.path === "/login") {
    // If the user is already authenticated and tries to access the login page,
    // redirect them to the homepage or a protected page of your choice.
    next("/protected"); // or '/dashboard' or any other path
  } else {
    // Proceed as normal if the route doesn't require authentication
    // or if the user is authenticated.
    next();
  }
});

export default router;
