<template>
  <div class="content">
    <b-img
      :src="iconImage"
      fluid
      alt="Orangie and Me"
      class="icon-image mb-2"
    ></b-img>
    <div class="text-msg mb-2">Hello Princess!</div>
    <div class="message-container">
      <p class="mb-3">
        Happy anniversary, darling! Can you believe it's been a whole year
        already? Time truly flies. This time, I wanted to do something a bit
        different, a bit more personal than an email. So, I’ve put together a
        private website just for us.
      </p>
      <p class="mb-3">
        It's a little corner of the internet where we can keep track of all the
        beautiful moments we've shared—our milestones, those sweet nicknames we
        call each other, and all the little things that mean so much. I built it
        from scratch with my own hands, and I designed it so we can keep adding
        to it as our love story unfolds.
      </p>
      <p class="mb-3">
        Please, take a moment to explore it, using the buttons I’ve placed both
        above and below this message. I really hope it brings a smile to your
        face.
      </p>

      <b-container>
        <b-row class="justify-content-md-center py-4">
          <b-col md="2" class="py-2">
            <b-button @click="goToTimeline" class="custom-button"
              >Timeline</b-button
            >
          </b-col>
          <b-col md="2" class="py-2">
            <b-button @click="goToTerms" class="custom-button"
              >Terms of Endearment</b-button
            >
          </b-col>
        </b-row>
      </b-container>
      <p>
        P.S. This is the project I’ve been secretly working on for the past
        month or so. I’m thrilled to finally show it to you.
      </p>
    </div>
  </div>
</template>

<script>
import iconImage from "../assets/icon.webp";

export default {
  name: "PrivateHome",
  data() {
    return {
      iconImage,
    };
  },
  props: {},
  methods: {
    goToTimeline() {
      // Navigate to the timeline route
      this.$router.push("/timeline");
    },
    goToTerms() {
      // Navigate to the terms route
      this.$router.push("/terms-of-endearment");
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-image {
  max-width: 150px; /* Adjust width as needed, considering responsiveness */
}

.text-msg {
  font-size: 3pc; /* Adjust font size as needed */
  font-weight: bold; /* Optional: makes the greeting stand out */
  color: #333; /* Adjust text color for better readability */
}

.message-container p {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  text-align: center; /* Centers the message text for better readability */
  max-width: 60%; /* Limits the width of text for optimal reading experience */
  margin: auto; /* Auto margin for horizontal centering */
  font-size: 1.3pc; /* Adjust based on preference */
  line-height: 1.4; /* Improves readability */
  justify-content: flex-end;
}

.custom-button {
  background-color: #ffffff; /* Light orange background */
  border: 2px solid #ff9f00; /* Slightly darker orange border */
  color: #242424; /* Dark gray text color for contrast */
  font-weight: bold; /* Makes the button text stand out */
  border-radius: 5px; /* Rounded corners for a softer look */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effects */
}

.custom-button:hover {
  background-color: #ff9f00; /* Lighter orange on hover for interactive feel */
  transform: translateY(-2px); /* Slight lift effect on hover */
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover for depth */
}
</style>
