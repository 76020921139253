import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { db } from "@/firebaseConfig"; // Import db from firebaseConfig

export default {
  state: {
    events: [],
    currentEvent: null,
  },
  getters: {
    events(state) {
      return state.events;
    },
    currentEvent(state) {
      return state.currentEvent;
    },
  },
  mutations: {
    SET_EVENTS(state, events) {
      state.events = events;
    },
    SET_CURRENT_EVENT(state, event) {
      state.currentEvent = event;
    },
  },
  actions: {
    async fetchEvents({ commit }) {
      try {
        const eventsCollection = collection(db, "events");
        const querySnapshot = await getDocs(eventsCollection);
        const eventsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        commit("SET_EVENTS", eventsList);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    },
    async fetchEventById({ commit }, eventId) {
      try {
        const eventDoc = await getDoc(doc(db, "events", eventId));
        if (eventDoc.exists()) {
          const event = {
            id: eventDoc.id,
            ...eventDoc.data(),
          };
          commit("SET_CURRENT_EVENT", event);
          return event;
        } else {
          console.error("Event not found");
          return null;
        }
      } catch (error) {
        console.error("Error fetching event by ID:", error);
        throw error;
      }
    },
    async addEvent({ dispatch }, event) {
      try {
        await addDoc(collection(db, "events"), event);
        // After successfully adding a new event, fetch all events again to update the state
        await dispatch("fetchEvents"); // Dispatching fetchEvents action to refresh the events list
        return true;
      } catch (error) {
        console.error("Error adding document:", error);
        return false;
      }
    },
    async addEventWithImage({ dispatch }, eventData) {
      try {
        let imageUrl = "";
        let originalFileName = "";

        // Check if the type is "image"
        if (eventData.type === "image") {
          const storage = getStorage();
          const timestamp = Date.now();
          const fileName = `${timestamp}_${eventData.imageFile.name}`;
          const imageRef = ref(storage, "images/" + fileName);
          originalFileName = eventData.imageFile.name;

          // Upload image to Firebase Storage
          const snapshot = await uploadBytesResumable(
            imageRef,
            eventData.imageFile
          );

          console.log("Uploaded", snapshot.totalBytes, "bytes.");
          console.log("File metadata:", snapshot.metadata);

          // Get a download URL for the uploaded image
          imageUrl = await getDownloadURL(snapshot.ref);
        }

        // Save event details including image URL to Firestore
        const event = {
          ...eventData,
          imageUrl,
          originalFileName,
        }; // Update imageUrl property
        delete event.imageFile; // Remove the imageFile property
        await addDoc(collection(db, "events"), event);

        // After successfully adding a new event, fetch all events again to update the state
        await dispatch("fetchEvents");

        return true;
      } catch (error) {
        console.error("Upload failed", error);
        return false;
      }
    },
    async editEventWithImage({ dispatch }, eventData) {
      try {
        console.log("Editing event with image:", eventData);
        let imageUrl = eventData.imageUrl || "";
        let originalFileName = eventData.originalFileName || "";

        if (eventData.type === "image" && eventData.imageFile) {
          console.log("Uploading new image file:", eventData.imageFile);
          const storage = getStorage();
          const timestamp = Date.now();
          const fileName = `${timestamp}_${eventData.imageFile.name}`;
          const imageRef = ref(storage, "images/" + fileName);
          originalFileName = eventData.imageFile.name;

          const snapshot = await uploadBytesResumable(
            imageRef,
            eventData.imageFile
          );

          console.log("Uploaded", snapshot.totalBytes, "bytes.");
          console.log("File metadata:", snapshot.metadata);

          imageUrl = await getDownloadURL(snapshot.ref);
        }

        // Remove imageFile property from eventData
        delete eventData.imageFile;

        const eventRef = doc(db, "events", eventData.id);
        await updateDoc(eventRef, {
          ...eventData,
          date: eventData.date,
          description: eventData.description,
          type: eventData.type,
          imageUrl,
          originalFileName,
        });

        console.log("Event edited successfully.");

        await dispatch("fetchEvents");

        return true;
      } catch (error) {
        console.error("Error editing event with image:", error);
        return false;
      }
    },
  },
};
