// main.js
import Vue from "vue";
import App from "./App.vue";
import { auth } from "./firebaseConfig"; // Ensure Firebase initializes before the store
import store from "./store";
import router from "./router";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

let app;

// Dispatch handleAuthStateChanged and wait for its completion before initializing the app
store.dispatch("handleAuthStateChanged").then(() => {
  // Initialize Vue app after authentication state has been handled
  app = new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount("#app");
});

// Ensure Firebase initializes before the store
auth.onAuthStateChanged(() => {
  if (!app) {
    // Store and router initialization will be handled after authentication state has been checked
    return;
  }
});
